import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';
import { ThemeContext } from '../layouts';
import Blog from '../components/Blog';
import Hero from '../components/Hero';
import Seo from '../components/Seo';
import { withTrans } from '../i18n/withTrans';
import { getCurrentLang } from '../utils/helpers';



class IndexPage extends React.Component {
	separator = React.createRef();

	scrollToContent = e => {
		this.separator.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
	};

	render() {
		const {
			i18n,
			data: {
				bgDesktop: { resize: { src: desktop } },
				bgTablet: { resize: { src: tablet } },
				bgMobile: { resize: { src: mobile } },
				site: { siteMetadata: { facebook } },
			}
		} = this.props;

		const backgrounds = {
			desktop,
			tablet,
			mobile
		};

		const language = getCurrentLang(i18n.language);

		const posts = this.props.data[`posts${language}`].edges;
		const trendsPost = this.props.data[`trends${language}`].edges;
		const trendPost = this.props.data[`trend${language}`].edges;
		const mostread = this.props.data[`mostread${language}`].edges;

		return (
			<React.Fragment>
				<ThemeContext.Consumer>
					{theme => <Hero trendPost={trendPost} trendsPost={trendsPost} scrollToContent={this.scrollToContent} backgrounds={backgrounds} theme={theme} {...this.props} />}
				</ThemeContext.Consumer>
				<hr ref={this.separator} />
				<ThemeContext.Consumer>{theme => <Blog mostread={mostread} posts={posts} theme={theme}  {...this.props} />}</ThemeContext.Consumer>

				<Seo facebook={facebook} {...this.props} />

				<style jsx>{`
					hr {
						margin: 0;
						border: 0;
					}
				`}</style>
			</React.Fragment>
		);
	}
}

IndexPage.propTypes = {
	data: PropTypes.object.isRequired
};

export default withTrans(IndexPage);

//eslint-disable-next-line no-undef
export const query = graphql`
	query IndexQuery {
		trendes:  allMarkdownRemark(
			filter: {frontmatter: {lang: {eq: "es"}}, fileAbsolutePath: {regex: "//posts/[0-9]+.*--/"}}
			limit: 1
			sort: { fields: [frontmatter___trend], order: DESC }
		  ) {
			edges {
				node {
					excerpt
					timeToRead
					fields {
						slug
						prefix
					}
					frontmatter {
						title
						category
						author
						cover {
							children {
								... on ImageSharp {
									fluid(maxWidth: 1200, maxHeight: 1200) {
										...GatsbyImageSharpFluid_withWebp
									}
								}
							}
						}
					}
				}
			}
		}
		trenden:  allMarkdownRemark(
			filter: {frontmatter: {lang: {eq: "en"}}, fileAbsolutePath: {regex: "//posts/[0-9]+.*--/"}}
			limit: 1
			sort: { fields: [frontmatter___trend], order: DESC }
		  ) {
			edges {
				node {
					excerpt
					timeToRead
					fields {
						slug
						prefix
					}
					frontmatter {
						title
						category
						author
						cover {
							children {
								... on ImageSharp {
									fluid(maxWidth: 1200, maxHeight: 1200) {
										...GatsbyImageSharpFluid_withWebp
									}
								}
							}
						}
					}
				}
			}
		}
		trendses:  allMarkdownRemark(
			filter: {frontmatter: {lang: {eq: "es"}}, fileAbsolutePath: {regex: "//posts/[0-9]+.*--/"}}
			limit: 3
			skip: 1
			sort: { fields: [frontmatter___trend], order: DESC }
		  ) {
			edges {
				node {
					excerpt
					timeToRead
					fields {
						slug
						prefix
					}
					frontmatter {
						title
						category
						author
						cover {
							children {
								... on ImageSharp {
									fluid(maxWidth: 100, maxHeight: 100) {
										...GatsbyImageSharpFluid_withWebp
									}
								}
							}
						}
					}
				}
			}
		}
		trendsen:  allMarkdownRemark(
			filter: {frontmatter: {lang: {eq: "en"}}, fileAbsolutePath: {regex: "//posts/[0-9]+.*--/"}}
			limit: 3
			skip: 1
			sort: { fields: [frontmatter___trend], order: DESC }
		  ) {
			edges {
				node {
					excerpt
					timeToRead
					fields {
						slug
						prefix
					}
					frontmatter {
						title
						category
						author
						cover {
							children {
								... on ImageSharp {
									fluid(maxWidth: 100, maxHeight: 100) {
										...GatsbyImageSharpFluid_withWebp
									}
								}
							}
						}
					}
				}
			}
		}
		mostreades:  allMarkdownRemark(
			filter: {frontmatter: {lang: {eq: "es"}}, fileAbsolutePath: {regex: "//posts/[0-9]+.*--/"}}
			limit: 4
			sort: { fields: [frontmatter___score], order: DESC }
		  ) {
			edges {
				node {
					excerpt
					timeToRead
					fields {
						slug
						prefix
					}
					frontmatter {
						title
						category
						author
						cover {
							children {
								... on ImageSharp {
									fluid(maxWidth: 100, maxHeight: 100) {
										...GatsbyImageSharpFluid_withWebp
									}
								}
							}
						}
					}
				}
			}
		}
		mostreaden:  allMarkdownRemark(
			filter: {frontmatter: {lang: {eq: "en"}}, fileAbsolutePath: {regex: "//posts/[0-9]+.*--/"}}
			limit: 4
			sort: { fields: [frontmatter___score], order: DESC }
		  ) {
			edges {
				node {
					excerpt
					timeToRead
					fields {
						slug
						prefix
					}
					frontmatter {
						title
						category
						author
						cover {
							children {
								... on ImageSharp {
									fluid(maxWidth: 100, maxHeight: 100) {
										...GatsbyImageSharpFluid_withWebp
									}
								}
							}
						}
					}
				}
			}
		}
		postses: allMarkdownRemark(
			filter: {frontmatter: {lang: {eq: "es"}}, fileAbsolutePath: {regex: "//posts/[0-9]+.*--/"}}
			sort: { fields: [fields___prefix], order: DESC }
		) {
			edges {
				node {
					excerpt
					fields {
						slug
						prefix
					}
					frontmatter {
						lang
						ref
						title
						category
						author
						cover {
							children {
								... on ImageSharp {
									fluid(maxWidth: 800, maxHeight: 460) {
										...GatsbyImageSharpFluid_withWebp
									}
								}
							}
						}
					}
				}
			}
		}
		
		postsen: allMarkdownRemark(
			filter: {frontmatter: {lang: {eq: "en"}}, fileAbsolutePath: {regex: "//posts/[0-9]+.*--/"}}
			sort: { fields: [fields___prefix], order: DESC }
		) {
			edges {
				node {
					excerpt
					fields {
						slug
						prefix
					}
					frontmatter {
						lang
						ref
						title
						category
						author
						cover {
							children {
								... on ImageSharp {
									fluid(maxWidth: 800, maxHeight: 460) {
										...GatsbyImageSharpFluid_withWebp
									}
								}
							}
						}
					}
				}
			}
		}
	
		site {
			siteMetadata {
				facebook {
					appId
				}
			}
		}
		bgDesktop: imageSharp(fluid: { originalName: { regex: "/cockpit/" } }) {
			resize(width: 1200, quality: 100, cropFocus: CENTER) {
				src
			}
		}
		bgTablet: imageSharp(fluid: { originalName: { regex: "/cockpit/" } }) {
			resize(width: 800, height: 1100, quality: 100, cropFocus: CENTER) {
				src
			}
		}
		bgMobile: imageSharp(fluid: { originalName: { regex: "/cockpit/" } }) {
			resize(width: 600, height: 850, quality: 100, cropFocus: CENTER) {
				src
			}
		}
	}
`;

//hero-background
