import React from 'react';
import PropTypes from 'prop-types';

import Img from 'gatsby-image';

import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import { Link } from 'gatsby';

const MostReadItem = props => {
    const { theme, post } = props;
    const {
        node: {
            timeToRead,
            excerpt,
            fields: { slug, prefix },
            frontmatter: {
                title,
                category,
                author,
                cover: {
                    children: [{ fluid }]
                }
            }
        }
    } = post;

    return (
        <React.Fragment>

            <div className="item-trend">
                <div className="item-trend-desc">
                    <Link to={slug}>
                        <div className="image-container-read" >
                            <Img className="gatsby-image-outer-wrapper" fluid={fluid}></Img>
                        </div>
                    </Link>
                </div>
                <div className="item-trend-desc row-spacing">
                    <Link to={slug}>
                        <div className="item-left-side">
                            <h3 className="item-title">
                                {title}
                            </h3>
                            <div className="item-date">
                                {prefix}
                            </div>
                        </div>
                    </Link>
                </div>
            </div>

            {/* --- STYLES --- */}
            <style jsx>{`

                :global(.image-container-read){
                    height: 80px;
                    width: 80px;
                    margin: 0px auto 0px 0px;

                    @from-width tablet {
                        height: 70px;
                        width: 70px;
                        margin: 0px 0px 0px auto;
                    }
                }
                :global(.gatsby-image-outer-wrapper) {
                    overflow: hidden;
                    border-radius: 5px;
                    transition: all ${theme.time.duration.default};
                    &:hover { 
                        transform: scale(1.05);
                    } 
                }
                :global(.row-spacing){
                    padding-left: 10px;
                  
                }
				.trends-list {
                    width: 100%;
                    height: 100%;
                }
                .item-date{
                    margin-top: 0.6em;
                    font-size: 0.75em;
                    @from-width tablet {
                        margin-top: 0.6em;
                    }
                }
                .item-title{
                    font-size: 16px;
                    font-weight: 600;
                    color: #2b2b2b;
                }
               .item-trend {
                    margin: 1.2em 0em;
                    display: flex;
                }
			`}</style>
        </React.Fragment>
    );
};

MostReadItem.propTypes = {
    theme: PropTypes.object.isRequired,
    post: PropTypes.object.isRequired,
};

export default MostReadItem;
