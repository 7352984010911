import React from "react";
import PropTypes from "prop-types";
import { Helmet } from 'react-helmet';
// import FollowMe from "./FollowMe";
import SidebarTitle from "./SidebarTItle";
import AboutDesc from "./AboutDesc";
import SmallSuscriptionForm from "../Suscription/SmallSuscription";
import MostReadItem from './MostReadItem';


const SiderbarInfo = props => {
    const { theme, follow, mostread, withAd = false, withSmallAd = false, t, language } = props;

    return (
        <React.Fragment>
            <div className="sidebar-info">

                <div className="content-sidebar-title">
                    <SidebarTitle theme={theme} title={t('sidebar.stayConnected')} />
                    {follow}
                </div>

                <div className="content-sidebar-title">
                    <SidebarTitle theme={theme} title={t('sidebar.about')} />
                    <AboutDesc {...props} t={t} language={language} theme={theme} />
                </div>

                <div className="content-sidebar-title">
                    <SidebarTitle theme={theme} title={t('sidebar.subscribe')} />
                    <SmallSuscriptionForm t={t} language={language} theme={theme} />
                </div>




                <div className="content-sidebar-title">
                    <SidebarTitle theme={theme} title={t('sidebar.mostViewed')} />
                    <ul>
                        {mostread.map(post => {
                            const {
                                node,
                                node: {
                                    fields: { slug }
                                }
                            } = post;
                            return <MostReadItem t={t} language={language} key={slug} post={post} theme={theme} />;
                        })}
                    </ul>
                </div>
                <div style={{ textAlign: 'center' }} className="content-sidebar-title" >
                    <div dangerouslySetInnerHTML={{ __html: `<iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=14&l=ur1&category=hotnewreleases&banner=1BWCMZYQA5F2PESPW182&f=ifr&linkID=3c0ee06557c3f31f8246e3e799c7f5d4&t=aviationconpa-20&tracking_id=aviationconpa-20" width="160" height="600" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>` }} />
                </div>

            </div>
            {/* --- STYLES --- */}
            <style jsx>{`

                .sidebar-info {
                    padding-left: 1em;
                    padding-right: 1em;
                }

                @from-width tablet {
                    .sidebar-info {
                        margin-top: 15.5px;
                        padding-left: 0em;
                        padding-right: 0em;
                    }
                }
                @from-width desktop {
                .sidebar-info {
                    margin-top: 0px;
                    padding-left: 1.5em;
                    padding-right: 1.5em;
                }
            }
         
           .content-sidebar-title {
               margin-bottom:  40px;
           }
      `}</style>
        </React.Fragment >
    );
};

SiderbarInfo.propTypes = {
    theme: PropTypes.object.isRequired,
    follow: PropTypes.any.isRequired,
    mostread: PropTypes.array.isRequired,
    withAd: PropTypes.bool,
    withSmallAd: PropTypes.bool,
    t: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
};

export default SiderbarInfo;
