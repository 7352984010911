import React from 'react';
import PropTypes from 'prop-types';
import Img from "gatsby-image";
import { Link } from 'gatsby';


import Row from 'antd/lib/row';
import Col from 'antd/lib/col';

import { IoMdTime } from "react-icons/io";

import TrendsPost from './TrendsPost';
import { getRouteByLang, BASIC_ROUTES } from '../../utils/helpers';


import 'antd/lib/grid/style/index.css';

const Hero = props => {
	const { scrollToContent, backgrounds, theme, trendPost, trendsPost, t, i18n } = props;

	const trend = trendPost[0];

	const {
		node: {
			timeToRead,
			excerpt,
			fields: { slug, prefix },
			frontmatter: {
				title,
				category,
				author,
				cover: {
					children: [{ fluid }]
				}
			}
		}
	} = trend;


	return (
		<React.Fragment>
			<section className="hero">

				<Img className="hero-image-wrapper" fluid={fluid}></Img>
				<div className="layer">
					<div className="content-info-trend">
						<Row className="content-info-trend" align="bottom">
							<Col className="container-title" xs={24} sm={16}>
								<Link to={slug}><h1>{title}</h1></Link>
								<ul >
									<li><span className="by">{t('post.by')}</span><span className="author"><Link to={getRouteByLang(i18n.language, BASIC_ROUTES.about)}>{author}</Link></span></li>
									<li>{prefix}</li>
									<li><IoMdTime /> {timeToRead} min </li>
								</ul>
								<p>{excerpt}</p>
							</Col>
							<Col xs={24} sm={8}>
								<TrendsPost theme={theme} trendsPost={trendsPost} />
							</Col>
						</Row>
					</div>
				</div>

			</section>

			{/* --- STYLES --- */}
			<style jsx>{`
				.hero :global(.hero-image-wrapper) {
					min-height: 210vw;
					@from-width tablet {
						height: 75vh ;
						min-height: 75vh;
						@media only screen and (orientation: landscape) {
							height: 105vw !important;
							min-height: 105vw !important;
						}
					}

					 
					@from-width desktop {
						height: 65vh !important;
						min-height: 65vh !important;

					}
				}
				.hero :global(.content-info-trend){
						padding: 0 1.1em calc(20px * 2);
						max-width: 1000px;
						margin: 0em auto 0em auto;
						width: 100%;
						height: 100%;
				}

				.hero  :global(.container-title) {
					padding: 0 20px;
					margin-bottom: 0;
					@from-width tablet {
						width: 66.56666667%;
						margin-bottom: 20px;
					}

				}

				.hero :global(.content-info-trend){
					padding: 1.3rem 0;
					-webkit-box-align: end;
					-ms-flex-align: end;
					align-items: flex-end;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-orient: horizontal;
					-webkit-box-direction: normal;
					-ms-flex-flow: row wrap;
					flex-flow: row wrap;
					height: 100%;

					h1 { 
						color: rgba(255,255,255,1);
						font-size: 2.0rem;
						line-height: 1.1;
						@from-width tablet {
							font-size: 2.6rem;
							line-height: 1;
						}
					}

					p {
						margin-top: 1.1em;
						font-size: 0.9em;
						color: rgba(255,255,255,.75);
						@from-width tablet {
						
							margin-top: 1.4em;
							font-size: 1.05em;

						}

						
					}

					ul {
						margin-top: 1.2em;
						color: rgba(255,255,255,.75);
						font-size: 0.9em;
						letter-spacing: 0;
						text-transform: none;
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						-ms-flex-wrap: wrap;
						flex-wrap: wrap;

						@from-width tablet {
							margin-top: 1.4em;
							font-size: 1.05em;
						}

						li { 
							color: rgba(255,255,255,.75);
							display: -webkit-box;
							display: -ms-flexbox;
							display: flex;
							-ms-flex-wrap: wrap;
							flex-wrap: wrap;
							-webkit-box-align: center;
							-ms-flex-align: center;
							align-items: center;

							&:not(:last-child)::after {
								margin-right: .5rem;
								content: "•";
								margin-left: .5rem;
							}
							.by { 
								margin-right: .5rem;
							}
							.author :global(a) {
									color: rgba(255,255,255,.75);
									font-weight: 600;
							}							
						}

						li :global(svg) {
							margin-right: 0.3em;
						}

						
					}
				 }

				 
				.hero {
					position: relative;
					display: flex;
					flex-flow: column nowrap;
					min-height: 210vw;
					margin-top: ${theme.header.height.homepage};

					@from-width tablet {
						min-height: 75vh;
						height: 75vh !important;
						@media only screen and (orientation: landscape) {
							height: 105vw !important;
							min-height: 105vw !important;
						}
					}

				
					@from-width desktop {
						min-height: 65vh !important;
						height: 65vh !important;
					}
				}
				.layer {
					background-color: rgba(10,10,10,.4);
					 position: absolute!important; 
					 top: 0; 
					 right: 0; 
					 bottom: 0; 
					 left: 0; 
					 width: 100%; 
					 height: 100%;
				}
			`}</style>
		</React.Fragment>
	);
};

Hero.propTypes = {
	scrollToContent: PropTypes.func.isRequired,
	backgrounds: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	trendPost: PropTypes.array.isRequired,
	trendsPost: PropTypes.array.isRequired,
	language: PropTypes.string.isRequired,
	t: PropTypes.func.isRequired,
};

export default Hero;
