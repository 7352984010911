/* eslint no-unused-vars: 0 */

import { navigate } from 'gatsby';
import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import config from '../../../content/meta/config';

const FormItem = Form.Item;
const { TextArea } = Input;
import 'antd/lib/form/style/index.css';
import 'antd/lib/input/style/index.css';
import 'antd/lib/button/style/index.css';

import SendOutlined from '!svg-react-loader!../../images/svg-icons/send.svg?name=SendOutlined';

import { ThemeContext } from '../../layouts';

const SmallSuscription = props => {
    const { getFieldDecorator } = props.form;

    const { t } = props;

    const [loading, setLoading] = useState(false);
    const [subbmitted, setSubbmitted] = useState(false);

    function encode(data) {
        return Object.keys(data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&');
    }

    function handleSubmit(e) {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                sendMessage(values);
            }
        });
    }

    async function sendMessage(values) {
        setLoading(true);
        const { email } = values;
        if (email) {
            const { result, msg, params } = await addToMailchimp(email);
            if (msg === 'captcha') {
                const url = `${config.mailchimpBase}/subscribe/post?u=${params.u}&id=${params.id}&EMAIL=${encodeURIComponent(
                    params.EMAIL
                )}`;

                window.open(url);
            } else if (result === 'success') {
                setSubbmitted(true);
            }
            setLoading(false);

        }
        // const result = await addToMailchimp;
    }

    function handleNetworkError(e) {
        console.log('submit Error');
    }

    return (
        <React.Fragment>
            <ThemeContext.Consumer>
                {theme => (

                    <div>
                        <div className="form">
                            {subbmitted && <p>${t('sidebar.emailDescMsg')}</p>}
                            {!subbmitted && <Form layout="inline" name="contact" onSubmit={handleSubmit}>
                                <FormItem className="select-container">
                                    {getFieldDecorator('email', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '',
                                                whitespace: true,
                                                type: 'email'
                                            }
                                        ]
                                    })(<Input className="custom-input" name="email" placeholder={t('sidebar.email')} />)}
                                </FormItem>
                                <Button htmlType="submit" className="button-template" shape="round"  >
                                    <SendOutlined width={25} height={25} />
                                </Button>
                            </Form>}
                        </div>

                        <div className="label-muted">
                            * {t('sidebar.emailDesc')}
                        </div>


                        {/* --- STYLES --- */}
                        <style jsx>{`
						div :global(.ant-form-item-control-wrapper) {
                            width: 100%;
                        }
                        div :global(.select-container) {
                            width: 100%;  
                            margin-bottom: 0; 
                        }
                        .form { 
                            position: relative;
                        }
                        .form :global(.ant-form-explain){
                            display: none;
                        }

                        .label-muted { 
                                margin-left:2px;
                                font-size: 12px;
                                font-style: italic;
                                line-height: 1.5;
                                margin-top: 1.5em;
                                color: ${theme.color.neutral.gray.e}
                        }
                       .fomr :global(.muted){
                            font-size: 12px;
                            font-style: italic;
                            line-height: 1.5;
                            margin-top: 1em;
                          }
                        .form :global(.ant-input) {
                            font-style: italic;
                            padding: 2em 2.5em;
                            border-radius: 100px;
                            &:hover {
                                border-color: ${theme.color.brand.primary};
                              }
                        }
                        .form :global(.ant-btn){
                            height: 3.9em;
                            width: 7em;
                            text-align: center;
                            padding-top: 0.5em;
                            padding-right: 2em;
                            display: block;
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            background-color: transparent;
                            border: 0;

                            &:hover {
                                color: ${theme.color.brand.primary};
                                box-shadow: 0;
                            }
                        }

						`}</style>
                    </div>

                )}
            </ThemeContext.Consumer>
        </React.Fragment>
    );
};

SmallSuscription.propTypes = {
    form: PropTypes.object,
    t: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired
};

const SmallSuscriptionForm = Form.create({})(SmallSuscription);

export default SmallSuscriptionForm;
