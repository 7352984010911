import { FaArrowRight } from "react-icons/fa/";
import { FaCalendar } from "react-icons/fa/";
import { FaTag } from "react-icons/fa/";
import { FaUser } from "react-icons/fa/";
import Img from "gatsby-image";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import ReadMore from "./ReadMore";

import { getRouteByLang, BASIC_ROUTES } from '../../utils/helpers';


const Item = props => {
  const {
    index,
    t,
    theme,
    post: {
      excerpt,
      fields: { slug, prefix },
      frontmatter: {
        title,
        category,
        author,
        cover: {
          children: [{ fluid }]
        }
      }
    }
  } = props;

  return (
    <React.Fragment>
      {(index != 0 && index % 2 === 0) && <div className="adss-mobile" style={{ textAlign: 'center', marginBottom: '1em' }} >
        <div dangerouslySetInnerHTML={{ __html: `<iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=42&l=ur1&category=hotnewreleases&banner=1FB4DBB4DCMF6P7DT2G2&f=ifr&linkID=37df0986700aceee6aeff81c01e53a35&t=aviationconpa-20&tracking_id=aviationconpa-20" width="234" height="60" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>` }} />
      </div>}
      <li>
        <Link to={slug} key={slug} className="link">
          <div className="gatsby-image-outer-wrapper">
            <Img fluid={fluid} />
          </div>
          <h1>
            {title}
          </h1>
          <p className="meta">

            <span>
              {t('post.by')} {author} • {prefix}
            </span>
            {category && (
              <span>
                <FaTag size={18} /> {t(`categories.${category}`)}
              </span>
            )}
          </p>

          <p>{excerpt}</p>
          <ReadMore {...props} theme={theme} />

        </Link>
      </li>

      {/* --- STYLES --- */}
      <style jsx>{`
        :global(.link) {
          width: 100%;
          color: ${theme.text.color.primary};
        }

        li {
          border: 1px solid transparent;
          padding: ${theme.space.inset.s};
          position: relative;
          transition: all ${theme.time.duration.default};
          background: transparent;

          :global(.gatsby-image-outer-wrapper) {
            border: 1px solid ${theme.line.color};
            overflow: hidden;
            border-radius: 5px;
          }
          :global(.gatsby-image-outer-wrapper img) {
            z-index: -1;
          }

          &::after {
            content: "";
            height: 0;
            position: absolute;
            bottom: ${`calc(${theme.space.default} * -1.5)`};
            left: 50%;
            transform: translateX(-50%);
            transition: all ${theme.time.duration.default};
            width: 50%;
          }

          &:first-child {
            &::before {
              content: "";
              height: 0;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              transition: all ${theme.time.duration.default};
              width: 50%;
            }
          }
        }

        h1 {
          padding: ${theme.space.m} 0 0;
          line-height: 1.2;
          font-size: 1.6em;
          text-remove-gap: both;
          color: black;
        }

        .meta {
          display: flex;
          flex-flow: row wrap;
          font-size: 0.8em;
          padding: 1.1em 0em 0.4em;;
          background: transparent;

          :global(svg) {
            fill: ${theme.icon.color};
            margin: ${theme.space.inline.xs};
          }
          span {
            align-items: center;
            display: flex;
            text-transform: uppercase;
            margin: ${theme.space.xs} ${theme.space.s} ${theme.space.xs} 0;
          }
        }

        p {
          line-height: 1.5;
          padding: 0.5em 0;
          text-remove-gap: both;
          font-size: 0.9em;
        }

        @from-width tablet {
          li {
            padding: 15px;

            &::after {
              bottom: ${`calc(${theme.space.default} * -2)`};
            }

            &:first-child {
              &::before {
                top: ${`calc(${theme.space.default} * -1.75)`};
              }
            }
          }

          h1 {
            font-size: 1.8em;
            padding: ${`calc(${theme.space.default} * 1.5) 0 0`};
            transition: all 0.5s;
          }
          .meta {
            padding: 1.2em 0em 0.2em;;
          }
          p {
            padding: 0.8em 0;
          }
        }
        @from-width desktop {
          li {
            padding: 0 1.1em ${`calc(${theme.space.default} * 2)`};

            &::after {
              bottom: ${`calc(${theme.space.default} * -1.5)`};
            }

            &:first-child {
              &::before {
                top: ${`calc(${theme.space.default} * -2.75)`};
              }
            }
          }

          :global(.blogItemLink:first-child) > li::before {
            top: ${`calc(${theme.space.default} * -2.75)`};
          }
          h1 {
            font-size: 2.1em;
            padding: ${`calc(${theme.space.default} * 1.2) 0 0`};
          }
          .meta {
            padding: 1.5em 0em 0.5em;
          }
          p {
            padding: 1em 0em;
          }
          li {
            &:hover {
              &:after {
                bottom: ${`calc(${theme.space.default} * -2.5)`};
              }
              :global(.gatsby-image-wrapper) {
                transform: scale(1.1);
              }
              h1 {
                color: ${theme.blog.h1.hoverColor};
              }
            
            }
            :global(.gatsby-image-wrapper) {
              transition: all ${theme.time.duration.default};
            }
         
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Item.propTypes = {
  post: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired
};

export default Item;
