import PropTypes from "prop-types";
import React from "react";
import StickyBox from "react-sticky-box/dist/esnext";


import Row from 'antd/lib/row';
import Col from 'antd/lib/col';


import 'antd/lib/grid/style/index.css';

import Item from "./Item";
import SiderbarInfo from "./SiderbarInfo";
import FollowMe from "./FollowMe";
import FollowMeMobile from "./FollowMeMobile";

const Blog = props => {
  const { posts, theme, mostread } = props;

  const follow = <FollowMe theme={theme} />;
  const followMobile = <FollowMeMobile theme={theme} />;

  return (
    <React.Fragment>
      <main className="main">
        <div className="ads-tablet" style={{ textAlign: 'center' }} >
          <div dangerouslySetInnerHTML={{ __html: `<iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=26&l=ur1&category=hotnewreleases&banner=06FC6VD7P84322KNP0R2&f=ifr&linkID=546bee6bbc4c828bef76be62f0f14acf&t=aviationconpa-20&tracking_id=aviationconpa-20" width="468" height="60" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>` }} />
        </div>
        <div className="ads-mobile" style={{ textAlign: 'center' }} >
          <div dangerouslySetInnerHTML={{ __html: `<iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=hotnewreleases&banner=00GP90RCZFRVTGTTPT02&f=ifr&linkID=d05ced90a705c688b598a586a8e22b1f&t=aviationconpa-20&tracking_id=aviationconpa-20" width="300" height="250" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>` }} />
        </div>
        <Row>

          <Col xs={24} sm={16}>
            <ul>
              {posts.map(post => {
                const {
                  node,
                  node: {
                    fields: { slug }
                  }
                } = post;
                return <Item index={posts.indexOf(post)} {...props} key={slug} post={node} theme={theme} />;
              })}
            </ul>
          </Col>

          <StickyBox className="computer ant-col ant-col-xs-24 ant-col-sm-8" offsetBottom={30} offsetTop={80}>
            <SiderbarInfo {...props} withAd={true} mostread={mostread} follow={follow} theme={theme} />

          </StickyBox>

          <div className="mobile ant-col ant-col-xs-24 ant-col-sm-8">
            <SiderbarInfo {...props} mostread={mostread} follow={followMobile} theme={theme} />
          </div>


        </Row>


      </main>

      {/* --- STYLES --- */}
      <style jsx>{`

        .ads-mobile{
          margin-top: 1em;
          display: block;
        }        
        .ads-tablet {
          display: none;
        }
        :global(.computer) {
            display: none;
        }
        :global(.mobile) {
            display: block;
            margin-top:30px;
        }
        .main {
          padding: 0 10px;
        }

        ul {
          list-style: none;
          margin: 0 auto;
        }

        @above tablet {
          .ads-mobile{
            display: none;
          }   
          .ads-tablet{
            display:block;
          }
          .main {
            padding: 0 0;
            margin-top: 20px;
          }
          :global(.computer) {
            display: block;
          }
          :global(.mobile) {
              display: none;
          }
        }
        @above desktop {
          .ads-tablet{
            display:block;
            margin-bottom: 1.2em;
          }
          .ads-mobile{
            display: none;
          }   
          .main {
            max-width: 1000px;
            margin: 2.0em auto 0em auto;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Blog.propTypes = {
  posts: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
  mostread: PropTypes.array.isRequired,
};

export default Blog;
