import React from "react";
import PropTypes from "prop-types";
import config from '../../../content/meta/config';

import FacebookIcon from '!svg-react-loader!../../images/svg-icons/i8-facebook.svg?name=FacebookLogo';
import InstagramIcon from '!svg-react-loader!../../images/svg-icons/i8-instagram.svg?name=InstagramLogo';
import TwitterIcon from '!svg-react-loader!../../images/svg-icons/i8-twitter.svg?name=TwitterLogo';


const buttonSize = 65;

const FollowMe = props => {
    const { theme } = props;

    return (
        <React.Fragment>

            <div className="social-icons-personal">

                <a rel="noopener noreferrer" href={`${config.authorSocialLinks.facebook}`} target="_blank">
                    <FacebookIcon width={buttonSize} height={buttonSize} />
                    <div className="sh-heading-font">Like</div>
                </a>
                <a rel="noopener noreferrer" href={`${config.authorSocialLinks.twitter}`} target="_blank">
                    <TwitterIcon width={buttonSize} height={buttonSize} />
                    <div className="sh-heading-font">Follow</div>
                </a>

                <a rel="noopener noreferrer" href={`${config.authorSocialLinks.instagram}`} target="_blank">
                    <InstagramIcon width={buttonSize} height={buttonSize} />
                    <div className="sh-heading-font">Follow</div>
                </a>
            </div>


            {/* --- STYLES --- */}
            <style jsx>{`
           
           .social-icons-personal {
                width: 100%;
           }
            .social-icons-personal :global(a) {
                width: 33.3%;
                display: inline-block;
                vertical-align: top;
                margin-right: -4px;
                background-color: transparent;
                text-align: center
            }
            :global(.sh-heading-font) {
                text-align: center;
                color: ${theme.color.neutral.gray.i};
                text-transform: uppercase;
                font-weight: 700;
                font-size: 11px;
            }
      `}</style>
        </React.Fragment>
    );
};

FollowMe.propTypes = {
    theme: PropTypes.object.isRequired
};

export default FollowMe;
