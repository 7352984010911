import React from "react";
import PropTypes from "prop-types";



const SidebarTitle = props => {
    const { theme, title } = props;

    return (
        <React.Fragment>
            <div className="sidebar-title">
                <h3>{title}</h3>
            </div>
            {/* --- STYLES --- */}
            <style jsx>{`
           .sidebar-title {
                margin-bottom: 20px;
                border-bottom: 2px solid #f0f0f0;

                :global(h3) {
                    bottom: -2px;
                    padding-bottom: 15px;
                    margin-bottom: 0;
                    border-bottom: 2px solid ${theme.color.neutral.gray.i};
                    display: inline-block;
                    vertical-align: top;
                    position: relative;
                    overflow: hidden;
                   font-size: 1.3em;
                   color: ${theme.color.neutral.gray.i}
               }
           }
            
      `}</style>
        </React.Fragment>
    );
};

SidebarTitle.propTypes = {
    theme: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired
};

export default SidebarTitle;
