import React from 'react';
import PropTypes from 'prop-types';

import Img from 'gatsby-image';

import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import { Link } from 'gatsby';

const TrendItem = props => {
    const { theme, post } = props;


    const {
        node: {
            timeToRead,
            excerpt,
            fields: { slug, prefix },
            frontmatter: {
                title,
                category,
                author,
                cover: {
                    children: [{ fluid }]
                }
            }
        }
    } = post;

    return (
        <React.Fragment>

            <Row className="item-trend">
                <Col xs={16} sm={16}>
                    <Link to={slug}>
                        <div className="item-left-side">
                            <h3 className="item-title">
                                {title}
                            </h3>
                            <div className="item-date">
                                {prefix}
                            </div>
                        </div>
                    </Link>
                </Col>
                <Col xs={8} sm={8} >
                    <Link to={slug}>
                        <div style={{ height: 80, width: 80, margin: '0px 0px 0px auto' }}>
                            <Img className="gatsby-image-outer-wrapper" fluid={fluid}></Img>
                        </div>
                    </Link>
                </Col>
            </Row>

            {/* --- STYLES --- */}
            <style jsx>{`

                :global(.gatsby-image-outer-wrapper) {
                    overflow: hidden;
                    border-radius: 5px;
                    transition: all ${theme.time.duration.default};
                    &:hover { 
                        transform: scale(1.05);
                    } 
                }
                .item-left-side{
                    
                }
				.trends-list {
                    width: 100%;
                    height: 100%;
                    background-color: rgb(112, 79, 175);
                }
                .item-date{
                    color: rgba(255,255,255,.75);
                    margin-top: 0.4em;
                    font-size: 0.75em;

                    @from-width tablet {
                        margin-top: 0.4em;
                    }
                }
                .item-title{
                    color: rgba(255,255,255);
                    font-size: 1.05em;
                    line-height: 1.2;
                    font-weight: 400;
                    @from-width tablet {
                        line-height: 1.25;
                        font-weight: 600;
                        font-size: 1.2em;
                    }
                }
               :global(.item-trend) {
                    margin: 1.2em 0em;
                }
			`}</style>
        </React.Fragment>
    );
};

TrendItem.propTypes = {
    theme: PropTypes.object.isRequired,
    post: PropTypes.object.isRequired,
};

export default TrendItem;
