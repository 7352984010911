import React, { } from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery, Link, useStaticQuery } from "gatsby";

import { getRouteByLang, BASIC_ROUTES, getCurrentLang } from '../../utils/helpers';

import Button from 'antd/lib/button';
import 'antd/lib/button/style/index.css';


const AboutDesc = props => {
  const { theme, language, t, data, i18n } = props;
  const lang = getCurrentLang(i18n.language);

  const query = useStaticQuery(graphql`
	query AboutMeQuery {
		aboutmeen: markdownRemark(fileAbsolutePath: { regex: "/aboutme-en/" }) {
			id
			html
		}
    aboutmees: markdownRemark(fileAbsolutePath: { regex: "/aboutme-es/" }) {
			id
			html
		}
	}
`);
  const about = query[`aboutme${lang}`];
  return (

    <React.Fragment>
      <div className="content-desc" dangerouslySetInnerHTML={{ __html: about.html }} theme={theme} />
      <Link to={getRouteByLang(lang, BASIC_ROUTES.about)}>
        <Button shape="round" type="primary" htmlType="submit" >
          {t('sidebar.showMore')}
        </Button>
      </Link>


      {/* --- STYLES --- */}
      <style jsx>{`

                .content-desc :global(.gatsby-resp-image-wrapper) {
                    overflow: hidden;
                    border-radius: 5px;
                    transition: all ${theme.time.duration.default};
                    &:hover { 
                        transform: scale(1.02);
                    } 
                  }

                    :global(.ant-btn-primary) {
                      height: auto;
                      font-size: 0.8em;
                      padding: 0.3em 2em;
                      background: ${theme.color.brand.primary};
                      border: 1px solid ${theme.color.brand.primary};
                    }
                    .content-desc  {
                      .padding-top: 1em;
                    }

                    .content-desc :global(p) { 
                      margin-bottom: 1em !important;
                      font-size: 0.9em;
                      line-height: 1.9;
                      color: ${theme.color.neutral.gray.j}
                    }
                `}</style>
    </React.Fragment>

  );
};

AboutDesc.propTypes = {
  theme: PropTypes.object.isRequired
};

export default AboutDesc;
