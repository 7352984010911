import React from "react";
import PropTypes from "prop-types";

import Row from 'antd/lib/row';
import Col from 'antd/lib/col';

const ReadMore = props => {
    const { theme, t } = props;

    return (
        <React.Fragment>

            <div className="post-readmore sh-table">

                <div className="sh-table-cell post-readmore-text">
                    <strong>{t('post.readMore')}</strong>
                </div>

                <div className="sh-table-cell post-readmore-line">
                    <div className="post-readmore-line-content"></div>
                </div>
                <div className="sh-table-cell">

                </div>
            </div>


            {/* --- STYLES --- */}
            <style jsx>{`
            strong {
                font-size: 1em;
            }
            .post-readmore {
                white-space: nowrap;
                min-height: 45px;
            }
            .sh-table {
                display: table;
                width: 100%;
            }
            .sh-table-cell {
                display: table-cell;
                vertical-align: middle;
                float: none!important;
                strong {
                    text-transform: uppercase;
                }
            }
            .post-readmore-line {
                width: 100%;
                position: relative;
            }
            .post-readmore-line-content {
                content: "";
                position: absolute;
                left: 15px;
                right: 15px;
                top: 50%;
                height: 1px;
                background-color: #f0f0f0;
            }

           
        .article {
          padding: ${theme.space.inset.default};
          margin: 0 auto;
        }
        @from-width tablet {
          .article {
            padding: ${`calc(${theme.space.default}) calc(${theme.space.default} * 2)`};
            max-width: ${theme.text.maxWidth.tablet};
          }
        }
        @from-width desktop {
          .article {
            padding: ${`calc(${theme.space.default} * 2 + 90px) 0 calc(${
                theme.space.default
                } * 2)`};
            max-width: ${theme.text.maxWidth.desktop};
          }
        }
      `}</style>
        </React.Fragment>
    );
};

ReadMore.propTypes = {
    theme: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default ReadMore;
