import React from 'react';
import PropTypes from 'prop-types';
import TrendItem from './TrendItem';

const TrendsPost = props => {
    const { theme, trendsPost } = props;


    return (
        <React.Fragment>

            <div className="trends-list">
                {trendsPost.map((post) => {
                    const {
                        node,
                        node: {
                            fields: { slug }
                        }
                    } = post;
                    return <TrendItem key={slug} theme={theme} post={post} />
                }
                )}
            </div>

            {/* --- STYLES --- */}
            <style jsx>{`
				.trends-list {
                    width: 100%;
                    height: 100%;
                    background-color: rgba(112, 79, 175,0.7);
                    padding: 1em 20px 1em 20px;
                    margin-top: 20px;
                    margin-bottom: 20px;

                    @from-width tablet {
						margin-top: 0;
					}
                }
			`}</style>
        </React.Fragment>
    );
};

TrendsPost.propTypes = {
    theme: PropTypes.object.isRequired,
    trendsPost: PropTypes.array.isRequired,
};

export default TrendsPost;
